<template>
  <div class="studio-card">
    <v-btn
      v-if="$store.getters['auth/isLoggedIn']"
      color="primary"
      @click.native="
        $route.params.pre
          ? $router.push({ name: 'appointmentList' })
          : $router.back()
      "
      style="
        position: absolute;
        height: 30px;
        left: 15px;
        top: 15px;
        z-index: 5;
      "
      outlined
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t("back") }}
    </v-btn>
    <v-img
      class="white--text align-end image-header"
      :src="background_image"
      style="height: 300px"
    >
      <v-row :justify="center_image_align" align="center" style="height: 300px">
        <v-col
          cols="8"
          sm="4"
          md="2"
          class="mx-2"
          v-bind:class="[
            {
              'profile-image ': edit,
            },
          ]"
        >
          <FileUpload
            class="pointer"
            :multiple="false"
            :drop="false"
            :drop-directory="false"
            accept="image/*"
            input-id="center_file"
            v-model="center_file"
            @input-file="inputCenterFile"
            v-if="edit"
          >
            <img
              width="100%"
              :src="center_image"
              v-bind:style="[
                {
                  'border-radius': center_image_mark,
                },
              ]"
            />

            <div>
              {{ $t("studios.preview.click_image") }}
            </div>
          </FileUpload>
          <img width="100%" :src="center_image" v-else />
        </v-col>
        <div v-if="edit" class="background-uploader">
          <FileUpload
            class="pointer"
            :multiple="false"
            :drop="true"
            :drop-directory="false"
            input-id="bg_file"
            accept="image/*"
            v-model="bg_file"
            @input-file="inputBGFile"
          >
            <div>
              {{ $t("studios.preview.drop_image") }}
            </div>
          </FileUpload>
        </div>
      </v-row>
    </v-img>
    <slot></slot>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "StudioCard",
  props: ["edit"],
  data() {
    return {
      bg_file: [],
      center_file: [],
    };
  },
  components: { FileUpload: () => import("vue-upload-component") },
  methods: {
    ...mapActions("auth", ["imageForm"]),

    inputBGFile(newFile, oldFile) {
      console.log("BG");
      if (this.bg_file.length)
        this.$confirm(this.$t("studios.preview.change_bg_image"))
          .then(() => {
            let formData = new FormData();
            formData.append("photo", newFile.file);
            formData.append("key", "background_image");
            this.imageForm(formData).then((response) => {
              this.$emit("reload");
            });
          })
          .finally(() => {
            this.bg_file = [];
          });
    },
    inputCenterFile(newFile, oldFile) {
      console.log("CENTER");
      if (this.center_file.length)
        this.$confirm(this.$t("studios.preview.change_center_image"))
          .then(() => {
            let formData = new FormData();
            formData.append("photo", newFile.file);
            formData.append("key", "center_image");
            this.imageForm(formData).then((response) => {
              this.$emit("reload");
            });
          })
          .finally(() => {
            this.center_file = [];
          });
    },
  },
  computed: {
    ...mapState("inscriptions", ["studioState"]),
    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
    center_image() {
      console.log(this.studio);
      if (this.studio && this.studio.user) {
        let s = this.studio.user.setting.find((s) => s.key === "center_image");
        if (s) return s.url;
      }
      return require("@/assets/logo.png");
    },
    center_image_mark() {
      console.log(this.studio);
      if (this.studio && this.studio.user) {
        let s = this.studio.user.setting.find(
          (s) => s.key === "center_image_mark"
        );
        if (s) return s.value;
      }
      return "0px";
    },
    center_image_align() {
      console.log(this.studio);
      if (this.studio && this.studio.user) {
        let s = this.studio.user.setting.find(
          (s) => s.key === "center_image_align"
        );
        if (s) return s.value;
      }
      return "center";
    },
    background_image() {
      if (this.bg_file.length === 1) {
        //return this.bg_file[0].data;
      }
      if (this.studio && this.studio.user) {
        let s = this.studio.user.setting.find(
          (s) => s.key === "background_image"
        );
        if (s) return s.url;
      }
      return require("@/assets/background.jpg");
    },
  },
};
</script>

<style lang="sass">
.studio-card
  .v-responsive__sizer
    display: none !important
  .v-stepper
    min-height: calc( 100vh - 300px )
  .image-header
    .background-uploader
      height: 300px
      width: 100%
      position: absolute
      top: 0
      left: 0

      .file-uploads
        background-color: rgba(0,0,0,0)
        height: 300px
        width: 100%
        display: block
        div
          display: none
          z-index: 12
          place-items: center
          height: calc(100% - 20px)
          border: 5px dashed var(--v-primary-base)
          margin: 10px
          border-radius: 28px
          color: var(--v-primary-base)
          font-size: 20px

      &:hover
        .file-uploads
          background-color: rgba(0,0,0,0.5)
          z-index: 11
          div
            display: grid

  .profile-image
    z-index: 10
    .file-uploads
      z-index: 12

      div
        position: absolute
        top: 0
        height: 100%
        display: none
        align-items: center
        color: var(--v-primary-base)
        font-size: 20px
        z-index: 20
    &:hover
      background-color: rgba(0,0,0,0.5)
      border-radius: 28px
      border: 5px dashed var(--v-primary-base)
      z-index: 20
      .file-uploads
        label
          z-index: 30
        div
          display: grid
          background-color: rgba(0,0,0,0.5)
</style>