var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"studio-card"},[(_vm.$store.getters['auth/isLoggedIn'])?_c('v-btn',{staticStyle:{"position":"absolute","height":"30px","left":"15px","top":"15px","z-index":"5"},attrs:{"color":"primary","outlined":""},nativeOn:{"click":function($event){_vm.$route.params.pre
        ? _vm.$router.push({ name: 'appointmentList' })
        : _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1):_vm._e(),_c('v-img',{staticClass:"white--text align-end image-header",staticStyle:{"height":"300px"},attrs:{"src":_vm.background_image}},[_c('v-row',{staticStyle:{"height":"300px"},attrs:{"justify":_vm.center_image_align,"align":"center"}},[_c('v-col',{staticClass:"mx-2",class:[
          {
            'profile-image ': _vm.edit,
          } ],attrs:{"cols":"8","sm":"4","md":"2"}},[(_vm.edit)?_c('FileUpload',{staticClass:"pointer",attrs:{"multiple":false,"drop":false,"drop-directory":false,"accept":"image/*","input-id":"center_file"},on:{"input-file":_vm.inputCenterFile},model:{value:(_vm.center_file),callback:function ($$v) {_vm.center_file=$$v},expression:"center_file"}},[_c('img',{style:([
              {
                'border-radius': _vm.center_image_mark,
              } ]),attrs:{"width":"100%","src":_vm.center_image}}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("studios.preview.click_image"))+" ")])]):_c('img',{attrs:{"width":"100%","src":_vm.center_image}})],1),(_vm.edit)?_c('div',{staticClass:"background-uploader"},[_c('FileUpload',{staticClass:"pointer",attrs:{"multiple":false,"drop":true,"drop-directory":false,"input-id":"bg_file","accept":"image/*"},on:{"input-file":_vm.inputBGFile},model:{value:(_vm.bg_file),callback:function ($$v) {_vm.bg_file=$$v},expression:"bg_file"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("studios.preview.drop_image"))+" ")])])],1):_vm._e()],1)],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }